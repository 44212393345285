import Vue from "vue";
import { Auth0Client } from "@auth0/auth0-spa-js";
import EventBus, { BusEvent } from '../event-bus';

/** Define a default action to perform after authentication */
// eslint-disable-next-line 
const DEFAULT_REDIRECT_CALLBACK = (appState?: any) => {  
    window.history.replaceState({}, document.title, window.location.pathname);
};

let instance: any;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    redirectUri = window.location.origin + '/auth',
    ...options
}) => {
    if (instance) return instance;
    
    // The 'instance' is simply a Vue object
    instance = new Vue({
        data() {
            return {
                loading: true,
                isAuthenticated: false,
                user: {},
                auth0Client: null,
                popupOpen: false,
                error: null
            };
        },
        methods: {
            /** Authenticates the user using a popup window */
            async loginWithPopup(o: any) {
                this.popupOpen = true;
                
                try {
                    await (this.auth0Client as any).loginWithPopup(o);
                } catch (e) {
                    // eslint-disable-next-line
                    console.error(e);
                } finally {
                    this.popupOpen = false;
                }
                
                this.user = await (this.auth0Client as any).getUser();
                this.isAuthenticated = true;
            },
            /** Handles the callback when logging in using a redirect */
            async handleRedirectCallback() {
                this.loading = true;
                try {
                    await (this.auth0Client as any).handleRedirectCallback();
    
                    this.user = await (this.auth0Client as any).getUser();
                    this.isAuthenticated = true;
                } catch (e) {
                    this.error = e;
                } finally {
                    this.loading = false;
                }
            },
            /** Authenticates the user using the redirect method */
            async loginWithRedirect(o: any) {
                await (this.auth0Client as any).loginWithRedirect(o);
            },
            /** Returns all the claims present in the ID token */
            getIdTokenClaims(o: any) {
                return (this.auth0Client as any).getIdTokenClaims(o);
            },
            /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
            async getTokenSilently(o: any) {
                const isAuthenticated = await (this.auth0Client as any).isAuthenticated()
                const token = isAuthenticated ? await (this.auth0Client as any).getTokenSilently(o) : null;
                return token
            },
            /** Gets the access token using a popup window */
            
            getTokenWithPopup(o: any) {
                return (this.auth0Client as any).getTokenWithPopup(o);
            },
            /** Logs the user out and removes their session on the authorization server */
            logout(o: any = { returnTo: window.location.origin }) {
                return (this.auth0Client as any).logout(o);
            }
        },
        /** Use this lifecycle method to instantiate the SDK client */
        async created() {
            (this.auth0Client as any) = await new Auth0Client({
                domain: options.domain,
                clientId: options.clientId,
                authorizationParams: {
                    audience: options.audience,
                    redirect_uri: redirectUri,
                  }
            });

            try {
                // If the user is returning to the app after authentication..
                if (
                    window.location.search.includes("code=") &&
                    window.location.search.includes("state=")
                ) {
                    // handle the redirect and retrieve tokens
                    const { appState } = await (this.auth0Client as any).handleRedirectCallback();
                    // Notify subscribers that the redirect callback has happened, passing the appState
                    // (useful for retrieving any pre-authentication state)
                    onRedirectCallback(appState);
                }
            } catch (e) {
                this.error = e;
            } finally {
                // Initialize our internal authentication state
                this.isAuthenticated = await (this.auth0Client as any).isAuthenticated();

                if (this.isAuthenticated) {
                    this.user = await (this.auth0Client as any).getUser();
                }

                if (!this.isAuthenticated) {
                    await this.loginWithRedirect({ redirect_uri: redirectUri });
                }

                this.loading = false;
                EventBus.$emit(BusEvent.Authed);
            }
        }
    });

    return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
    install(Vue: any, options: any) {
        Vue.prototype.$auth = useAuth0(options);
    }
};
