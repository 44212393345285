import { ClientProduct } from "@/typings/interfaces/client-product.interface";
import { Client } from "@/typings/interfaces/client.interface";
import { IEnvironment } from "@/typings/interfaces/environment.interface";
import { IManifest } from "@/typings/interfaces/manifest.interface";

export class State {
    environment: IEnvironment | null;
    showLoader: boolean;

    clients: Client[];
    selectedClient: Client | null;
    clientProducts: ClientProduct[];
    selectedClientProduct: ClientProduct | null;
    manifests: IManifest[];

    constructor() {
        this.environment = null;
        this.showLoader = false;
        this.selectedClient = null;
        this.clients = [];
        this.clientProducts = [];
        this.selectedClientProduct = null;
        this.manifests = [];
    }
}