
import Component from 'vue-class-component';
import BaseComponent from '@/components/BaseComponent';
import { PNLTableSettings } from './typing/pnl-table-settings';
import { PNLTableColumn } from './typing/pnl-table-column';
import { PNLTableAction } from './typing/pnl-table-action';

@Component({
  props: {
    tableId: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    tableSettings: {
      type: Object,
      default: null,
    },
  },
})
export default class PNLTable extends BaseComponent {
  currentPage = 0;
  hasSubRows = false;

  get settings(): PNLTableSettings {
    if (!this.$props || !this.$props.tableSettings)
      return new PNLTableSettings();
    return this.$props.tableSettings;
  }

  get columns(): PNLTableColumn[] {
    if (!this.settings) return [];
    return this.settings.columns;
  }

  get actions(): PNLTableAction[] {
    if (!this.settings) return [];
    if (!this.settings.actions) return [];
    if (!this.hasItems) return [];
    if (this.settings.showUnavailableActions)
      return this.settings.actions ?? [];
    if (this.settings.actionDisabled) return [];

    const toShowActions: PNLTableAction[] = [];
    toShowActions.concat(
      this.settings.actions.filter(
        (action: PNLTableAction) => !!action.isAvailable == false
      )
    );

    this.settings.actions
      .filter((action: PNLTableAction) => action.isAvailable)
      .forEach((action: PNLTableAction) => {
        this.$props.items.some((item: any) => {
          if (!action.isAvailable) return true;
          const result = action.isAvailable(item);

          if (result) {
            toShowActions.push(action);
            return result;
          }

          return false;
        });
      });

    return toShowActions;
  }

  get hasItems(): boolean {
    if (!Array.isArray(this.$props.items)) return false;

    return this.$props.items.length > 0;
  }

  get noItemsText(): string {
    return this.settings?.noItemsText ?? 'No items available';
  }

  get initialPage(): number {
    return this.settings.initialPage ?? 1;
  }

  get amountOfPages(): number {
    return this.settings.amountOfPages ?? 1;
  }

  get showPagination(): boolean {
    return !!this.settings.showPagination;
  }

  mounted() {
    this.currentPage = this.initialPage;

    this.$watch(
      () => this.$props.items,
      () => {
        setTimeout(() => {
          this.hasSubRows = this.checkSubRows();
        }, 200);
      }
    );
  }

  checkSubRows(): boolean {
    if (this.$props.items.length === 0) return false;

    return document.querySelectorAll(`#${this.$props.tableId} .sub`).length > 0;
  }

  emitEvent(action: PNLTableAction, item?: any, customActionId?: string) {
    if (!action && !customActionId) {
      this.$emit('row-click', item);
      return;
    }

    if (action.isAvailable && !action.isAvailable(item)) return;

    const actionID = action?.id ?? customActionId;

    this.$emit('tableEvent', { actionID, item: item ?? null });
  }

  emitPageChange(toPage: number) {
    if (toPage <= 0) return;
    if (toPage > this.amountOfPages) return;

    this.$emit('pageChange', toPage);
    this.currentPage = toPage;
  }

  public getColumnValueFromItem(col: PNLTableColumn, item: any): string {
    let toReturn: any = this._getValue(col.valueKey, item);
    if (!toReturn) {
      if (this.settings && col.undefinedValueFn) {
        toReturn = col.undefinedValueFn(item);
      } else {
        toReturn = '-';
      }
    } else {
      toReturn = col.formatFn ? col.formatFn(toReturn, item) : toReturn;
    }

    return toReturn;
  }

  private _getNestedValueFromObject(keys: string[], obj: any): any {
    let currentObj = obj;
    let value = null;

    if ((typeof obj).toString() !== 'object') {
      value = undefined;
    }

    if (value === null) {
      keys.some((key, i) => {
        if (currentObj[key]) {
          if (i === keys.length - 1) {
            value = currentObj[key];
            return true;
          } else {
            currentObj = currentObj[key];
          }
        } else {
          value = undefined;
          return true;
        }
      });
    }

    return value;
  }

  private _getValue(key: string, obj: any): any {
    let value: any = null;
    if (obj) {
      const keys = key.split('.');

      if (keys.length > 1) {
        value = this._getNestedValueFromObject(keys, obj);
      } else if (obj[key]) {
        value = obj[key];
      } else {
        value = undefined;
      }
    }

    return value;
  }
}
