
import Component from 'vue-class-component';
import BaseComponent from './components/BaseComponent';
import Header from './components/PNLHeader/Header.vue';
import Loader from './components/Loader.vue';
import AppContainer from '@/components/AppContainer.vue';
import Footer from '@/components/Footer.vue'
import { environment } from './utils/environment';
import jwt_decode from "jwt-decode";

@Component({
  components: {
    Header,
    Loader,
    AppContainer,
    Footer
  }
})
export default class App extends BaseComponent {
  orgName = '';
  userName = '';

  get envName(): string {
    try {
      return environment().envName;
    } catch (error) {
      return '';
    }
  }

  get isAuthed(): boolean {
    return ((this as any).$auth as any).isAuthenticated;
  }

  async getClaims(): Promise<any> {
    if (!(this as any).$auth.isAuthenticated) return null;
    const jwt = await (this as any).$auth.getTokenSilently();
    const decodedJwt: any = jwt_decode(jwt);
    this.userName = decodedJwt['https://postnl.nl/user_full_name'];
    this.orgName = decodedJwt['https://postnl.nl/user_organization'];
  }

  async mounted() {
    const checkAuthFn = () => (this as any).$auth.isAuthenticated;
    const setCheckInterval = () => setTimeout(async () => {
      if (!checkAuthFn()) {
        setCheckInterval();
      } else {
        await this.getClaims();
      }
    }, 250);

    setCheckInterval();
  }

  logout(): void {
    (this as any).$auth.logout();
  }
} 
