import { PNLTableColumn, IPNLTableColumn } from './pnl-table-column';
import { PNLTableAction, IPNLTableAction } from './pnl-table-action';

class TableSettings {
    columns?: PNLTableColumn[];
    actions?: PNLTableAction[];
    itemsPerPage?: number;
    actionDisabled?: boolean;
    noItemsText?: string;
    amountOfPages?: number;
    initialPage?: number;
    showPagination?: boolean;
    showUnavailableActions?: boolean;
    actionHeaderTitle?: string;
    showSubRow?: (item?: any) => boolean;
}

export class PNLTableSettings extends TableSettings {
    columns: PNLTableColumn[];
    actions: PNLTableAction[];
    itemsPerPage?: number;
    actionDisabled?: boolean;
    noItemsText?: string;
    amountOfPages?: number;
    initialPage?: number;
    showPagination?: boolean;
    showUnavailableActions?: boolean;
    actionHeaderTitle?: string;
    showSubRow?: (item?: any) => boolean;

    constructor(settings?: IPNLTableSettings) {
        super();
        this.columns =
            settings?.columns?.map(
                (col: PNLTableColumn) => new PNLTableColumn(col)
            ) ?? [];
        this.actions =
            settings?.actions?.map(
                (action: PNLTableAction) => new PNLTableAction(action)
            ) ?? [];
        this.itemsPerPage = settings?.itemsPerPage ?? undefined;
        this.actionDisabled = settings?.actionDisabled ?? false;
        this.noItemsText = settings?.noItemsText ?? undefined;
        this.amountOfPages = settings?.amountOfPages ?? undefined;
        this.initialPage = settings?.initialPage ?? 1;
        this.showPagination = settings?.showPagination ?? false;
        this.showUnavailableActions = settings?.showUnavailableActions ?? false;
        this.actionHeaderTitle = settings?.actionHeaderTitle ?? '';
        this.showSubRow = settings?.showSubRow ?? (() => false);
    }

    public addColumn(col: IPNLTableColumn): PNLTableSettings {
        this.columns.push(new PNLTableColumn(col));
        return this;
    }

    public addAction(action: IPNLTableAction): PNLTableSettings {
        this.actions.push(new PNLTableAction(action));
        return this;
    }
}

export interface IPNLTableSettings extends TableSettings {}
