import axios from "axios";

export default async function loadEnvironment(): Promise<any> {
    const envConfig = await axios.get(`${window.location.origin}/environments/env.json`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return await envConfig.data;
}