import Vue from 'vue';
import Vuex from 'vuex';
import { State } from './state.class';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

Vue.use(Vuex);

const getInitialState = () => new State();

export default new Vuex.Store({
  state: getInitialState(),
  mutations,
  actions,
  getters
})
