import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import loadEnvironment from './utils/load-config';
import vSelect from 'vue-select';
import { IEnvironment } from './typings/interfaces/environment.interface';
import storeTypes from './store/types';
import { ToastPlugin } from 'bootstrap-vue';
import { auth0 } from './utils/environment';
import { Auth0Plugin, getInstance } from './utils/auth';
import Axios, { AxiosResponse, AxiosError } from 'axios';
import EventBus, { BusEvent } from './utils/event-bus';
import { locationSearchToObject } from '@/utils/generic-functions';

function initAuth0(): void {
    const auth0Config = auth0();

    Vue.use(Auth0Plugin, {
        domain: auth0Config.domain,
        clientId: auth0Config.clientId,
        audience: auth0Config.audience,
        onRedirectCallback: (appState: any) => {
            router.push(
                appState && appState.targetUrl
                    ? appState.targetUrl
                    : window.location.pathname
            );
        },
    });
}

function initInterceptors(): void {
    // Outgoing request interceptor
    Axios.interceptors.request.use(
        async function(config) {
            const token = await getInstance().getTokenSilently();

            if (token) {
                config.headers = {
                    Authorization: `Bearer ${token}`,
                };
            }

            return config;
        },
        function(error) {
            return Promise.reject(error);
        }
    );

    // Response interceptor
    Axios.interceptors.response.use(
        function(response: AxiosResponse<any>) {
            return response;
        },
        function(error: AxiosError) {
            return Promise.reject(error);
        }
    );
}

Vue.config.productionTip = false;

Vue.component('v-select', vSelect);
Vue.use(ToastPlugin);

loadEnvironment().then((env: IEnvironment) => {
    let queryParams: string | null = null;
    const vueInstance = new Vue({
        router,
        store,
        render: h => h(App),
    });

    if (!window.location.href.includes('/auth')) {
        localStorage.setItem('params', window.location.search);
        vueInstance.$router.push({ name: 'AuthRedirect' });
    } else {
        const params = localStorage.getItem('params');
        if (params) {
            queryParams = params;
            localStorage.removeItem('params');
        }
    }

    EventBus.$on(BusEvent.Authed, () => {
        vueInstance.$router.push({
            name: 'Root',
            params: { queryParams: locationSearchToObject(queryParams ?? '') },
        });
    });

    (window as any).__app__ = vueInstance;

    vueInstance.$store.commit(storeTypes.mutations.SET_ENVIRONMENT, env);
    initAuth0();
    initInterceptors();
    vueInstance.$mount('#app');
});
