import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Root from '../views/Root.vue';
import Blank from '../views/Blank.vue';
import {getInstance} from "@/utils/auth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Root',
    component: Root,
    beforeEnter(to, from, next) {
      const checkAuth = () => {
        if (getInstance().isAuthenticated) {
          next()
        } else {
          setTimeout(checkAuth, 100);
        }
      };
      checkAuth();
    },
  },
  {
    path: '/auth',
    name: 'AuthRedirect',
    component: Blank
  }
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

export default router;
