import { ClientProduct } from "@/typings/interfaces/client-product.interface";
import { Client } from "@/typings/interfaces/client.interface";
import { apiBaseUrl } from "@/utils/environment";
import axios, { AxiosResponse } from "axios";

export function getClients(): Promise<AxiosResponse<Client[]>> {
    return axios.get(`${apiBaseUrl()}/clients`);
}

export function getClientProducts(clientCode: string): Promise<AxiosResponse<ClientProduct[]>> {
    return axios.get(`${apiBaseUrl()}/clients/${clientCode}/products`);
}