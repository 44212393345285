import FileSaver from 'file-saver';
import { AxiosResponse } from 'axios';

export default class FileFunctions {
    static getFileNameFromResponse(response: AxiosResponse): string | null {
        const resHeader: string = response.headers['content-disposition'] ?? "";

        const startIndex = resHeader.indexOf('filename=');
        const endIndex = resHeader.indexOf(';', startIndex);

        if (startIndex > -1 && endIndex > -1) {
            return resHeader
                .substring(startIndex, endIndex)
                .replace('filename=', '')
                .trim();
        }

        return null;
    }

    static downloadObjectAsJSON(obj: any, filename: string): void {
        (FileSaver as any).saveAs(
            new Blob([JSON.stringify(obj, null, 4)]),
            filename,
            {
                type: 'application/json',
            }
        );
    }

    static downloadObject(
        res: AxiosResponse,
        fallbackFileName: string,
        overwriteFileName?: string
    ): void {
        let filename;
        const type = res.headers['content-type'];

        if (overwriteFileName) {
            filename = overwriteFileName;
        } else {
            const headerValue = res.headers['content-disposition'];
            if (headerValue) {
                filename = headerValue.split('filename=')[1].split(';')[0];
            } else {
                filename = fallbackFileName;
            }
        }

        (FileSaver as any).saveAs(new Blob([res.data]), filename, {
            type,
        });
    }

    static downloadFromUrl(url: string): void {
        if (!url) return;
        try {
            window.open(url);
        } catch (error) {
            throw `Unable to download file from ${url}`;
        }
    }
}
