export function PascalCaseReadableText(s: string) {
    return s.replace(/([A-Z])/g, ' $1').trim();
}

export function Capitalize(s: string) {
    if (s.length > 0) {
        const char = s[0];
        const str = s.slice(1);

        return `${char.toUpperCase()}${str.toLowerCase()}`;
    }

    return '';
}

export function bytesToKilobytesConvertion(n: number) {
    return Math.round(n / 1000);
}

export function locationSearchToObject(locationSearch: string): any {
    const urlParams = new URLSearchParams(locationSearch);

    const obj: any = {};

    // iterate over all keys
    for (const key of urlParams.keys()) {
        if (urlParams.getAll(key).length > 1) {
            obj[key] = urlParams.getAll(key);
        } else {
            obj[key] = urlParams.get(key);
        }
    }

    return obj;
}
