import { IManifestResponse } from '@/typings/interfaces/manifest.interface';
import { apiBaseUrl } from '@/utils/environment';
import axios, { AxiosResponse } from 'axios';
import FileFunctions from '@/utils/file-functions';

export function getManifests(
    fileNameFilter?: string,
    page?: number,
    pageSize?: number
): Promise<AxiosResponse<IManifestResponse>> {
    let queryParams = '';

    if (fileNameFilter) {
        queryParams += `?fileNameFilter=${fileNameFilter}`;
    }

    if (page) {
        queryParams += `${queryParams.length > 0 ? '&' : '?'}page=${page}`;
    }

    if (pageSize) {
        queryParams += `${
            queryParams.length > 0 ? '&' : '?'
        }pageSize=${pageSize}`;
    }

    return axios.get(`${apiBaseUrl()}/manifest${queryParams}`);
}

export function postManifest(data: FormData): Promise<AxiosResponse> {
    return axios.post(`${apiBaseUrl()}/manifest`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });
}

export async function downloadManifestMetadata(
    manifestId: string
): Promise<AxiosResponse> {
    try {
        const result = await axios.get(
            `${apiBaseUrl()}/manifest/${manifestId}/manifest`
        );
        return result;
    } catch (error) {
        return error as any;
    }
}

export function getManifestDetails(manifestId: string): Promise<AxiosResponse> {
    return axios.get(`${apiBaseUrl()}/manifest/${manifestId}/details`);
}

export async function getValidatedManifestFile(manifestId: string) {
    try {
        const res = await axios.get(
            `${apiBaseUrl()}/manifest/${manifestId}/validated`
        );
        FileFunctions.downloadFromUrl(res.data.url);
        return res;
    } catch (error) {
        return error;
    }
}

export async function overrideManifestDecision(
    manifestId: string,
    clientCode: string,
    override: boolean
): Promise<AxiosResponse> {
    const data = { manifestId, override, clientCode };

    try {
        const res = await axios.post(`${apiBaseUrl()}/manifest/Override`, data);
        return res;
    } catch (error) {
        return error as any;
    }
}

export async function getAgents(): Promise<AxiosResponse<any>> {
    return axios.get(`${apiBaseUrl()}/manifest/agents`);
}
