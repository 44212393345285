import { ClientProduct } from "@/typings/interfaces/client-product.interface";
import { Client } from "@/typings/interfaces/client.interface";
import { IEnvironment } from "@/typings/interfaces/environment.interface";
import { IManifest } from "@/typings/interfaces/manifest.interface";
import { State } from "./state.class";

const mutations = {
    SET_ENVIRONMENT: (state: State, val: IEnvironment) => state.environment = val,
    SET_SHOW_LOADER: (state: State, val: boolean) => state.showLoader = val,
    SET_CLIENTS: (state: State, val: Client[]) => state.clients = val,
    SET_CLIENT_PRODUCTS: (state: State, val: ClientProduct[]) => state.clientProducts = val,
    SET_SELECTED_CLIENT: (state: State, val: Client) => state.selectedClient = val,
    SET_SELECTED_CLIENT_PRODUCT: (state: State, val: ClientProduct) => state.selectedClientProduct = val,
    SET_MANIFESTS: (state: State, val: IManifest[]) => state.manifests = val
}

export default mutations;