import { IPNLTableAction } from './pnl-table-action';

class TableColumn {
    label?: string;
    valueKey?: string;
    formatFn?: (val: any, item?: any) => any;
    undefinedValueFn?: (item: any) => string;
    alignment?: string;
    sortingFn?: (val1: any, val2: any) => number;
    action?: IPNLTableAction;
    description?: (item: any) => string;
    isLink?: (item: any) => boolean;
    linkFormat?: (item: any) => string;
}

export class PNLTableColumn extends TableColumn {
    label: string;
    valueKey: string;

    constructor(column: IPNLTableColumn) {
        super();
        this.label = column.label ?? '';
        this.valueKey = column.valueKey ?? '';
        this.formatFn = column.formatFn ?? undefined;
        this.undefinedValueFn = column.undefinedValueFn ?? undefined;
        this.alignment = column.alignment ?? undefined;
        this.sortingFn = column.sortingFn ?? undefined;
        this.action = column.action ?? undefined;
        this.description = column.description ?? undefined;
        this.isLink = column.isLink ?? undefined;
        this.linkFormat = column.linkFormat ?? undefined;
    }

    hasAction(): boolean {
        return !!this.action;
    }

    hasActionAvailable(value: any): boolean {
        if (!this.action) return false;

        if (this.action.isAvailable) {
            return this.action.isAvailable(value);
        }

        return true;
    }
}

export interface IPNLTableColumn extends TableColumn {}
