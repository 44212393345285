import { getClientProducts, getClients } from '@/api/clients.api';
import { getManifests } from '@/api/manifest.api';
import { ClientProduct } from '@/typings/interfaces/client-product.interface';
import { Client } from '@/typings/interfaces/client.interface';
import {
    IManifest,
    IManifestResponse,
} from '@/typings/interfaces/manifest.interface';
import { State } from './state.class';
import storeTypes from './types';
import { PascalCaseReadableText } from '@/utils/generic-functions';

const getState: (context: any) => State = (context: any) => context.state;

const actions = {
    TOGGLE_LOADER(context: any) {
        context.commit(
            storeTypes.mutations.SET_SHOW_LOADER,
            !getState(context).showLoader
        );
    },
    async FETCH_CLIENTS(context: any) {
        let clients: Client[];
        let response;

        try {
            response = await getClients();
            clients = response.data;
        } catch (error) {
            clients = [];
            response = error;
        }

        context.commit(storeTypes.mutations.SET_CLIENTS, clients);
        return response;
    },
    async FETCH_CLIENT_PRODUCTS(context: any, data: { clientCode: string }) {
        let clientsProducts: ClientProduct[];
        let response;

        if (!data.clientCode) {
            console.log('no client code was provided');
        }

        try {
            response = await getClientProducts(data.clientCode);
            clientsProducts = response.data;

            if (Array.isArray(clientsProducts) && clientsProducts.length > 0) {
                const clientType = getState(context).selectedClient?.uploadType;
                if (clientType) {
                    clientsProducts = [{ code: '', description: PascalCaseReadableText(clientType) }].concat(clientsProducts);
                }
            }
        } catch (error) {
            clientsProducts = [];
            response = error;
        }

        context.commit(
            storeTypes.mutations.SET_CLIENT_PRODUCTS,
            clientsProducts
        );

        return response;
    },
    async FETCH_MANIFESTS(
        context: any,
        data: { fileNameFilter?: string; page?: number; pageSize?: number }
    ) {
        let manifests: IManifest[];
        let response;

        try {
            response = await getManifests(
                data.fileNameFilter ?? undefined,
                data.page ?? undefined,
                data.pageSize ?? undefined
            );
            manifests = ((response.data as unknown) as IManifestResponse).data;
        } catch (error) {
            manifests = [];
            response = error;
        }

        context.commit(storeTypes.mutations.SET_MANIFESTS, manifests);
        return response;
    },
};

export default actions;
