
import Component from 'vue-class-component';
import BaseComponent from '@/components/BaseComponent';
import PNLTable from '@/components/PNLTable/PNLTable.vue';
import storeTypes from '@/store/types';
import { Client } from '@/typings/interfaces/client.interface';
import { ClientProduct } from '@/typings/interfaces/client-product.interface';
import FileInput from '../components/FileInput.vue';
import {
  downloadManifestMetadata,
  getManifestDetails,
  getValidatedManifestFile,
  overrideManifestDecision,
  postManifest,
  getAgents,
} from '@/api/manifest.api';
import ToastrHelper from '@/utils/toastr-helper';
import { AxiosResponse } from 'axios';
import debounce from 'debounce';
import { IManifest, IManifestDetailsReponse } from '@/typings/interfaces/manifest.interface';
import { ManifestStatus } from '@/typings/enums/manifest-status.enum';
import FileFunctions from '@/utils/file-functions';
import { PNLTableSettings } from '@/components/PNLTable/typing/pnl-table-settings';
import { PNLTableColumnAlignment } from '@/components/PNLTable/typing/pnl-column-alignment.enum';
import {
  bytesToKilobytesConvertion,
  Capitalize,
  PascalCaseReadableText,
} from '@/utils/generic-functions';
import { dayjsEx } from '@/utils/dayjs';
import { environment } from '@/utils/environment';

@Component({
  components: {
    FileInput,
    PNLTable,
  },
})
export default class Root extends BaseComponent {
  manifestSearchTerm = '';
  files: any[] = [];
  clientHasNoProducts = false;
  hasSetClient = false;
  manifestError: IManifestDetailsReponse | null = null;
  tempManifests: IManifest[] = [];
  clientOnEntry: string | null = null;

  tableSettings: PNLTableSettings | null = null;
  tablePageCount = 1;
  tableCurrentPage = 1;
  tablePageSize = 10;
  awaitingManifestsTable: PNLTableSettings | null = null;

  summaryTable: PNLTableSettings | null = null;
  errorReasonTable: PNLTableSettings | null = null;
  checkInterval: any = null;
  agents: { id: number; name: string }[] = [];

  mawb = '';
  hawb = '';
  mawbIsInvalid = false;
  agent = null;

  private _debounceGetFileOverview: Function | null = null;

  get fileInput(): FileInput | null {
    if (!this.$refs.fileInput) return null;

    return this.$refs.fileInput as any;
  }

  get clients(): Client[] {
    return this.$state.clients.sort((a: Client, b: Client) =>
      a.code.localeCompare(b.code)
    );
  }

  get clientProducts(): ClientProduct[] {
    return this.$state.clientProducts;
  }

  get manifestItems(): IManifest[] {
    return this.$state.manifests;
  }

  get errorSummary(): any[] {
    if (!this.manifestError) return [];
    const arr: any[] = [];
    Object.keys(this.manifestError.summary).forEach((k: string) => {
      const showError = (this.manifestError?.summary as any)[k] === -1;
      arr.push({
        label: Capitalize(k),
        count: showError
          ? 'Unknown|Invalid Manifest'
          : (this.manifestError?.summary as any)[k],
      });
    });
    return arr;
  }

  get errorReasons(): any[] {
    if (!this.manifestError) return [];
    return (this.manifestError.errors?.map(x => { return ({...x, type: 'error'} as any) })) ?? [];
  }

  get warningReasons(): any[] {
    if (!this.manifestError) return [];
    return (this.manifestError.warnings?.map(x => { return ({...x, type: 'warning'} as any) })) ?? [];
  }

  get detailReasons(): any[] {
    return [...this.errorReasons, ...this.warningReasons];
  }

  get canSubmit(): boolean {
    return !!(this.$state.selectedClient && this.files.length > 0);
  }

  private get _manifestSearchTerm(): any {
    return this.manifestSearchTerm.length > 0 ? this.manifestSearchTerm : null;
  }

  created() {
    this._debounceGetFileOverview = debounce(
      () => this.getFileOverview(true, true),
      500
    );
  }

  async mounted() {
    this._initFileTable();

    if ((this.$route.params.queryParams as any).clientCode) {
      this.clientOnEntry = (this.$route.params.queryParams as any).clientCode;
    }

    if (this.$state.selectedClient) {
      this.hasSetClient = true;
    }

    this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);

    const responses = await Promise.all([
      this.getAgents(),
      this.$store.dispatch(storeTypes.actions.FETCH_CLIENTS),
      this.$store.dispatch(storeTypes.actions.FETCH_MANIFESTS, {
        pageSize: this.tablePageSize,
      }),
    ]);

    if (this.clientOnEntry) {
      const client = this.clients.filter(
        (c: Client) =>
          c.code.toLowerCase() === this.clientOnEntry?.toLowerCase()
      );
      if (client.length > 0) {
        this.$store.commit(storeTypes.mutations.SET_SELECTED_CLIENT, client[0]);
        this.hasSetClient = true;
      }
    }

    if (responses[2]) {
      this._setPageCountFromResponse(responses[2]);
    }

    this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);

    this.$watch(
      () => this.manifestSearchTerm,
      () => {
        if (this._debounceGetFileOverview) {
          this._debounceGetFileOverview();
        }
      }
    );

    this.$watch(
      () => this.$state.manifests,
      () => {
        this._checkTempManifests();
      }
    );

    this.checkInterval = setInterval(() => {
      this.getFileOverview(false);
    }, 60000);
  }

  async getAgents(): Promise<void> {
    try {
      const response = await getAgents();
      this.agents = response.data.data;
    } catch (error) {
      this.agents = [];
    }
  }

  async handleTableEvent(e: { actionID: string; item: IManifest }) {
    if (e.actionID === 'download-metadata') {
      this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
      const result: any = await downloadManifestMetadata(e.item.manifestId);
      if (result.status === 200) {
        FileFunctions.downloadFromUrl(result.data.url);
      } else {
        ToastrHelper.errorToastr(
          `An error has occured while downloading the JSON: ${
            result.response.data.error ?? result.status
          }.`,
          this
        );
      }
      this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
    } else if (e.actionID === 'view-errors') {
      this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
      try {
        const result = await getManifestDetails(e.item.manifestId);
        this.manifestError = result.data;
      } catch (error) {
        ToastrHelper.errorToastr(
          `An error has occured while getting the manifest errors: ${
            (error as any).response.data.error ?? (error as any).status
          }.`,
          this
        );
      } finally {
        this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
      }
    }
  }

  async overrideManifest(item: IManifest): Promise<void> {
    this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
    try {
      await overrideManifestDecision(
        item.manifestId,
        item.clientCode ?? '',
        true
      );
      this.getFileOverview(false);
    } catch (error) {
      ToastrHelper.errorToastr('Failed to override manifest', this);
    } finally {
      this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
    }
  }

  async cancelOverride(item: IManifest): Promise<void> {
    this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
    try {
      await overrideManifestDecision(
        item.manifestId,
        item.clientCode ?? '',
        false
      );
      this.getFileOverview(false);
    } catch (error) {
      ToastrHelper.errorToastr('Failed to ignore override manifest', this);
    } finally {
      this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
    }
  }

  async downloadErrorManifest(): Promise<any> {
    const manifestId = this.manifestError?.manifestId;

    if (!manifestId) return;
    this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
    try {
      await getValidatedManifestFile(manifestId);
    } catch (error) {
      ToastrHelper.errorToastr(error as string, this);
    }
    this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
  }

  searchItem(options: any, search: string): any[] {
    return options.filter(
      (p: ClientProduct) =>
        p.code.toLowerCase().includes(search.toLowerCase()) ||
        p.description.toLowerCase().includes(search.toLowerCase())
    );
  }

  async getFileOverview(showLoader = true, resetPage = false): Promise<any> {
    if (showLoader) {
      this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
    }

    if (resetPage) {
      this.tableCurrentPage = 1;
    }

    (this.$refs['manifestTable'] as PNLTable).currentPage =
      this.tableCurrentPage;

    const data = await this.$store.dispatch(
      storeTypes.actions.FETCH_MANIFESTS,
      {
        fileNameFilter: this._manifestSearchTerm,
        page: this.tableCurrentPage,
        pageSize: this.tablePageSize,
      }
    );

    if (showLoader) {
      this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
    }

    this._setPageCountFromResponse(data);
  }

  private _setPageCountFromResponse(res: AxiosResponse): void {
    if (res.status !== 200) return;

    this.tablePageCount = res.data.pageCount ?? 0;

    if (this.tableSettings) {
      this.tableSettings.amountOfPages = this.tablePageCount;
    }
  }

  async postManifest(): Promise<any> {
    if (this.mawb) {
      const r = new RegExp('^([0-9]{3})-([0-9]{8})$');
      this.mawbIsInvalid = !r.test(this.mawb);
      if (this.mawbIsInvalid) {
        return;
      }
    }

    this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
    const apiCalls: any[] = [];

    this.files.forEach((file: any) => {
      const data = new FormData();
      data.append(
        'ClientCode',
        this.$state.selectedClient?.code.toString() ?? ''
      );
      data.append('File', file);

      if (this.mawb.trim()) {
        data.append('Mawb', this.mawb);
      }

      if (this.hawb.trim()) {
        data.append('Hawb', this.hawb);
      }

      if (this.agent !== null) {
        data.append('Agent', (this.agent as any).toString());
      }

      apiCalls.push({ apiCall: postManifest(data), file });
    });

    const errors: string[] = [];

    const results: PromiseSettledResult<AxiosResponse>[] =
      await Promise.allSettled(apiCalls.flatMap((item: any) => item.apiCall));

    results.forEach(
      (result: PromiseSettledResult<AxiosResponse>, i: number) => {
        if (result.status === 'fulfilled') {
          this._addTempManifest(apiCalls[i].file, result.value.data.value);
        } else if (result.reason.response.status === 422) {
          if (result.reason.response.data.fieldname === 'mawb') {
            this.mawbIsInvalid = true;
            errors.push('');
          } else {
            errors.push(
              `${(apiCalls[i].file as File).name}: ${
                result.reason.response.data.fieldname
              } ${result.reason.response.data.error}`
            );
          }
        } else {
          errors.push(`${(apiCalls[i].file as File).name}: Unexpected error`);
        }

        if (i === results.length - 1) {
          this.getFileOverview();
          this.$store.dispatch(storeTypes.actions.TOGGLE_LOADER);
          if (this.fileInput) {
            (this.fileInput as FileInput).resetFiles();
          }

          if (errors.length === 0) {
            this.mawb = '';
            this.hawb = '';
            this.agent = null;

            ToastrHelper.successToastr(
              'Succesfully uploaded manifest(s)',
              this
            );
          } else {
            const showableErrors = errors.filter((x) => x !== '');
            if (showableErrors.length > 0) {
              ToastrHelper.errorToastr(
                `One or more manifest were not succesfully uploaded. \n\n ${errors.join(
                  '\n\n'
                )}`,
                this
              );
            } else {
              ToastrHelper.errorToastr(
                'One or more manifest were not succesfully uploaded.',
                this
              );
            }
          }
        }
      }
    );
  }

  async setSelectedClient(e: any): Promise<void> {
    this.$store.commit(storeTypes.mutations.SET_SELECTED_CLIENT, e);
    this.hasSetClient = true;
  }

  setSelectedClientProduct(e: any): void {
    this.$store.commit(storeTypes.mutations.SET_SELECTED_CLIENT_PRODUCT, e);
  }

  changePage(pageNum: number): void {
    this.tableCurrentPage = pageNum;
    this.getFileOverview();
  }

  clientSelectLabelGen(option: any): string {
    if (typeof option === 'object') {
      return `${option.code} - ${option.name}`;
    }
    return option;
  }

  private _addTempManifest(file: File, manifestId: string): void {
    this.tempManifests.push({
      manifestId,
      sizeKb: bytesToKilobytesConvertion(file.size).toString(),
      date: new Date().toUTCString(),
      fileName: file.name,
      status: ManifestStatus.Uploaded,
      overrideStatus: 'None',
    } as any);
  }

  private _checkTempManifests(): void {
    const toRemove: IManifest[] = [];
    this.tempManifests.forEach((m: IManifest) => {
      const hasItem =
        this.$state.manifests.filter(
          (fM: IManifest) => fM.manifestId === m.manifestId
        ).length > 0;

      if (hasItem) {
        toRemove.push(m);
      }
    });

    toRemove.forEach((m: IManifest) => {
      this.tempManifests.splice(this.tempManifests.indexOf(m), 1);
    });
  }

  private _initFileTable(): void {
    const t = new PNLTableSettings()
      .addColumn({
        label: 'Filename/Manifest',
        valueKey: 'delivery',
        description: (item: IManifest) => item.fileName ?? 'Filename unknown',
        undefinedValueFn: (item: IManifest) => item.fileName ?? '-',
        isLink: (item: IManifest) =>
          !!item.delivery &&
          item.status &&
          item.status.toLowerCase() === ManifestStatus.Completed.toLowerCase(),
        linkFormat: (item: IManifest) =>
          `${environment().TTINTUrl}Manifest/Overview?delivery=${
            item.delivery
          }`,
      })
      .addColumn({
        label: 'Status',
        valueKey: 'status',
        formatFn: (val: string, manifest: IManifest) => {
          let toReturn = manifest.overrideStatus === 'Pending'
            ? 'Exists already'
            : PascalCaseReadableText(val);

          if (manifest.hasWarnings) {
            toReturn += ' ⚠️'
          }
          
          return toReturn;
        },
        action: {
          isAvailable: (item: IManifest) =>
            (item.status === ManifestStatus.InError && !!item.fileName) || item.hasWarnings,
          id: 'view-errors',
        },
      })
      .addColumn({
        label: 'Size kb',
        valueKey: 'sizeKb',
      })
      .addColumn({
        label: 'Date',
        valueKey: 'date',
        formatFn: (val: string) => {
          const date = dayjsEx.utc(val);
          return date.format('DD/MM HH:mm');
        },
      })
      .addAction({
        name: 'MANIFEST',
        id: 'download-metadata',
        isAvailable: (item: IManifest) =>
          item.status === ManifestStatus.Completed,
      });

    t.showPagination = true;
    t.amountOfPages = 1;
    t.actionHeaderTitle = 'Download';
    t.actionDisabled = false;
    t.showSubRow = (item: IManifest) => item.overrideStatus === 'Pending';

    this.tableSettings = t;
    const awaitingTable = new PNLTableSettings({ ...t });
    awaitingTable.columns[0].valueKey = 'fileName';
    awaitingTable.showPagination = false;
    this.awaitingManifestsTable = awaitingTable;

    const sumTable = new PNLTableSettings()
      .addColumn({
        label: 'Entries',
        valueKey: 'label',
        alignment: PNLTableColumnAlignment.Left,
      })
      .addColumn({
        label: 'Count',
        valueKey: 'count',
        alignment: PNLTableColumnAlignment.Right,
        undefinedValueFn: () => '0',
      });

    this.summaryTable = sumTable;

    const errorTable = new PNLTableSettings()
      .addColumn({
        label: 'Entries',
        valueKey: 'reason',
        alignment: PNLTableColumnAlignment.Left,
      })
      .addColumn({
        label: 'Count',
        valueKey: 'count',
        alignment: PNLTableColumnAlignment.Right,
        undefinedValueFn: () => ' ',
        formatFn: (value: any) => {
          if (value === null || value == 0 || value == -1) {
            return '';
          }

          return value;
        },
      }).
      addColumn({
        label: 'Type',
        valueKey: 'type',
        alignment: PNLTableColumnAlignment.Right,
        undefinedValueFn: () => ' '
      });

    this.errorReasonTable = errorTable;
  }

  beforeDestroy() {
    clearInterval(this.checkInterval);
    this.checkInterval = null;
  }
}
