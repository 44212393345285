
import Component from 'vue-class-component';
import BaseComponent from './BaseComponent';

@Component({
  props: {
    maxFiles: {
      default: 1,
    },
    acceptedFileTypes: {
      type: Array,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    showInputWhenDisabled: {
      type: Boolean,
      default: false,
    },
    initialValue: {
      type: Array,
      default: [],
    },
  },
})
export default class FileInput extends BaseComponent {
  public files: any[] = [];

  get acceptedFileTypesInput(): string {
    if (Array.isArray(this.$props.acceptedFileTypes)) {
      const joinString = ',';
      return this.$props.acceptedFileTypes.join(joinString);
    }

    return this.$props.acceptedFileTypes ? this.$props.acceptedFileTypes : '*';
  }

  get fileInput(): any | null {
    return this.$refs.input ?? null;
  }

  mounted(): void {
    this.files = this.$props.initialValue;
  }

  canAddFiles(): boolean {
    if (this.$props.isDisabled) return false;

    if (this.files.length < this.$props.maxFiles) return true;

    return false;
  }

  resetFiles(): void {
    this.files = [];
    this._emitFiles();
  }

  public addFile(e: any): void {
    const fileList = e.target.files as FileList;

  if (e && e.target.files[0]) {
      if (!this.canAddFiles()) {
        return;
      }

      const allowedFiles = this.$props.maxFiles - this.files.length;
      const loopRange = fileList.length <= allowedFiles ? fileList.length : Math.abs(allowedFiles - fileList.length);  

      if (loopRange >= 0) {
        for (let i = 0; i < loopRange; i++) {
          const file = fileList.item(i);

          if (!this.files) {
            this.files = [];
          }

          if (this._isAcceptedFileType(file)) {
            this.files.push(file);
          }
        }
        this._emitFiles();
      }
    }
  }

  public removeFile(e: Event, fileIndex: number): void {
    e.preventDefault();
    this.files.splice(fileIndex, 1);
    this._emitFiles();
  }

  private _isAcceptedFileType(file: any): boolean {
    const splitFileName = file.name.split('.');
    const fileExtension = splitFileName[splitFileName.length - 1];

    if (!fileExtension || fileExtension[0] === '') {
      return false;
    }

    if (this.$props.acceptedFileTypes) {
      if (Array.isArray(this.$props.acceptedFileTypes)) {
        let valid = false;
        this.$props.acceptedFileTypes.some((fileType) => {
          valid = fileType.replace('.', '') === fileExtension;
          return valid;
        });
        return valid;
      } else {
        return fileExtension === this.$props.acceptedFileTypes.replace('.', '');
      }
    } else {
      return true;
    }
  }

  private _emitFiles(): void {
    if (!this.fileInput) return;

    this.fileInput.value = null;
    this.$emit('change', this.files);
    this.$emit('input', this.files);
    this.$forceUpdate();
  }
}
