import { State } from '@/store/state.class';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class BaseComponent extends Vue {
    get $state(): State {
        return this.$store.state as State;
    }

    clog(val: any) {
        console.log(val);
    } 
}