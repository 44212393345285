
import { TTINTUrl } from '@/utils/environment';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    environmentName: {
      type: String,
      default: 'Environment name',
    },
    organisationName: {
      type: String,
      default: null,
    },
    userName: {
      type: String,
      default: null,
    },
    isAuthed: {
      type: Boolean,
      default: false,
    },
  },
})
export default class Header extends Vue {
  get firstLetterFromName(): string {
    if (!this.$props.userName) return '';

    return (this.$props.userName as string).charAt(0).toUpperCase();
  }

  get TTINTUrl(): string {
    return TTINTUrl();
  }

  emitLogout(): void {
    this.$emit('logout');
  }
}
