const storeTypes = {
    mutations: {
        SET_ENVIRONMENT: 'SET_ENVIRONMENT',
        SET_SHOW_LOADER: 'SET_SHOW_LOADER',
        SET_CLIENTS: 'SET_CLIENTS',
        SET_SELECTED_CLIENT: 'SET_SELECTED_CLIENT',
        SET_CLIENT_PRODUCTS: 'SET_CLIENT_PRODUCTS',
        SET_SELECTED_CLIENT_PRODUCT: 'SET_SELECTED_CLIENT_PRODUCT',
        SET_MANIFESTS: 'SET_MANIFESTS'
    },
    actions: {
        TOGGLE_LOADER: 'TOGGLE_LOADER',
        FETCH_CLIENTS: 'FETCH_CLIENTS',
        FETCH_CLIENT_PRODUCTS: 'FETCH_CLIENT_PRODUCTS',
        FETCH_MANIFESTS: 'FETCH_MANIFESTS'
    },
    getters: {
    }
};

export default storeTypes;