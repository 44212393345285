class TableAction {
    name?: string;
    id?: string;
    icon?: string;
    isAvailable?: (itemValue: any) => boolean;
    unavailableText?: string;
}

export class PNLTableAction extends TableAction {
    name!: string;
    id!: string;

    constructor(action: IPNLTableAction) {
        super();
        this.name = action.name ?? '';
        this.id = action.id ?? '';
        this.icon = action.icon ?? undefined;
        this.isAvailable = action.isAvailable ?? undefined;
        this.unavailableText = action.unavailableText ?? undefined;
    }
}

export interface IPNLTableAction extends TableAction {}